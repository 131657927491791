
export default {
  data: () => ({
    icons: [
      { name: "mdi-instagram", link: "https://www.instagram.com/matjrah_app/" },
      { name: "mdi-twitter", link: "https://twitter.com/matjrah_app" },
      {
        name: "mdi-whatsapp",
        link: "https://api.whatsapp.com/send?phone=966555252984",
      },
    ],
  }),
  computed: {
    links() {
      return [
        // { name: "الرئيسية", to: "/" },
        // { name: "الأسعار", to: "/prices" },
        // { name: "المميزات", to: "/features" },
        { name: "تبيع على انستجرام؟", to: "/instagram" },
        { name: "انضم لنظام العمولة ", to: "/affiliate" },
        { name: "نظام نقاط البيع POS ", to: "/pos" },
        { name: "الأسئلة الشائعة", to: "/faqs" },
        { name: "إنشاء متجر الكتروني متكامل", to: "/full-store" },
        // { name: "المدونة", href: "https://blog.matjrah.com/" },
      ];
    },
    linksKW() {
      return [
        { name: "تبيع على انستجرام؟", to: "/instagram" },
        { name: "الأسئلة الشائعة", to: "/faqs" },
        { name: "إنشاء متجر الكتروني متكامل", to: "/full-store" },
      ];
    },
    otherLinks() {
      return [
        // { name: "تطبيق المتجر", to: "/store-app" },
        { name: "الشروط والأحكام", to: "/terms-and-conditions" },
        { name: "سياسة الخصوصية", to: "/privacy-policy" },
        { name: " مركز المساعدة", href: "https://help.matjrah.com" },
        {
          name: " حالة المنصة",
          href: "https://stats.uptimerobot.com/Vr6DYs2YWo",
        },
        { name: " مقارنة سلة وزد مع متجرة ", to: "/matjrah-vs-salla-zid" },

        // { name: "تواصل معنا", to: "/contact-us" },
      ];
    },
  },
};
